import "./index.css";
import config from "./config";
import SimpleVideoCallWebsite from "./components/SimpleVideoCallWebsite";
import "./media/fontello/fontello-embedded.css";

import * as Backend from "@faintlines/backend-client";

import React from "react";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

if (process.env.NODE_ENV === "production" && config.backendUrl) {
	Backend.configure({
		baseUrl: config.backendUrl,
	});
}

ReactDOM.render(
	<React.StrictMode>
		<HelmetProvider>
			<Helmet>
				<title>{config.site.name}</title>
				{config.site.description ? (
					<meta
						name="description"
						content={config.site.description}
					/>
				) : null}
				{config.site.favicon ? (
					<link rel="icon" href={config.site.favicon} />
				) : null}
			</Helmet>
			<SimpleVideoCallWebsite />
		</HelmetProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
