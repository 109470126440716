import styles from "./VideoCall.module.css";
import CameraVideo from "./CameraVideo";
import config from "../config";

import * as Backend from "@faintlines/backend-client";
import VideoPlayer from "@faintlines/video-player";

import { useRef, useState } from "react";
import classnames from "classnames";

export default function VideoCall({ call, temporaryInteractionId }) {
    const [joined, setJoined] = useState(false);
    const [ended, setEnded] = useState(false);
    const [cameraOn, toggleCamera] = useState(false);
    const [sessionExpired, setSessionExpired] = useState(false);

    function handleToggleCamera(toggle) {
        toggleCamera(toggle);
    }

    function endCallHandler() {
        const doEnd = () => {
            setEnded(true);
            if (call.onEnd?.redirect) {
                setTimeout(
                    () => (window.location.href = call.onEnd.redirect),
                    2000
                );
            }
        };

        if (!temporaryInteractionId) {
            doEnd();
            return;
        }

        Backend.temporaryInteract({
            interactionType: "call_ended",
            temporaryInteractionId,
        })
            .then(doEnd)
            .catch(() => {
                setJoined(false);
                setEnded(false);
                setSessionExpired(true);
            });
    }

    function handleJoin() {
        if (!temporaryInteractionId) {
            setJoined(true);
            return;
        }
        Backend.temporaryInteract({
            interactionType: "joined_call",
            temporaryInteractionId,
        })
            .then(() => {
                setJoined(true);
            })
            .catch(() => {
                setSessionExpired(true);
            });
    }

    return (
        <div className={styles.videoCall}>
            {ended ? (
                <EndedScreen redirectUrl={call.onEnd?.redirect} />
            ) : joined ? (
                <ActiveVideoCall
                    call={call}
                    cameraOn={cameraOn}
                    onEnd={endCallHandler}
                    onToggleCamera={handleToggleCamera}
                />
            ) : (
                <IntroScreen
                    call={call}
                    cameraOn={cameraOn}
                    onToggleCamera={handleToggleCamera}
                    onJoin={handleJoin}
                    sessionExpired={sessionExpired}
                />
            )}
        </div>
    );
}

function IntroScreen({
    call,
    cameraOn,
    onToggleCamera,
    onJoin,
    sessionExpired,
}) {
    return (
        <div className={styles.intro}>
            <div className={styles.intro__panel}>
                <IntroCameraWrapper
                    cameraOn={cameraOn}
                    onToggleCamera={onToggleCamera}
                />
            </div>
            <div className={styles.intro__panel}>
                <div className={styles.intro__title}>{call.title}</div>
                {sessionExpired ? (
                    <div className={styles.intro__expire}>
                        {"Session Expired"}
                    </div>
                ) : (
                    <div className={styles.intro__join} onClick={onJoin}>
                        {"Join Call"}
                    </div>
                )}
            </div>
        </div>
    );
}

function EndedScreen({ redirectUrl }) {
    return (
        <div className={styles.ended}>
            <div className={styles.ended__title}>
                {config.site.callEndedText}
            </div>
            {redirectUrl ? (
                <a href={redirectUrl} className={styles.ended__redirect_link}>
                    {
                        "You are being redirected, please click here if your browser does not redirect you."
                    }
                </a>
            ) : null}
        </div>
    );
}

function IntroCameraWrapper({ cameraOn, onToggleCamera }) {
    return (
        <div className={styles.intro__cameraWrapper}>
            {cameraOn ? (
                <CameraVideo
                    className={styles.intro__camera}
                    onError={() => onToggleCamera(false)}
                />
            ) : null}
            <div className={styles.intro__controls}>
                <ControlsButton
                    icon="videocam"
                    isOn={cameraOn}
                    onClick={() => onToggleCamera(!cameraOn)}
                />
            </div>
        </div>
    );
}

function ActiveVideoCall({ call, cameraOn, onToggleCamera, onEnd }) {
    return (
        <div className={styles.activeCall}>
            <VideoGrid call={call} onEnd={onEnd} />
            <UserVideo cameraOn={cameraOn} onToggleCamera={onToggleCamera} />
            <Controls
                cameraOn={cameraOn}
                onToggleCamera={onToggleCamera}
                onEnd={onEnd}
            />
        </div>
    );
}

function VideoGrid({ call, onEnd }) {
    const endedVideos = useRef(0);
    const videoRefs = [];
    for (let i = 0; i < call.users.length; ++i) {
        videoRefs.push(useRef()); // eslint-disable-line
    }

    function handleVideoEnd(index) {
        endedVideos.current++;
        if (endedVideos.current === call.users.length) {
            onEnd && onEnd();
        }
    }

    return (
        <div className={styles.grid}>
            {call.users.map((user, i) => (
                <div key={i} className={styles.grid__cell}>
                    <VideoPlayer
                        ref={videoRefs[i]}
                        className={styles.grid__video}
                        hlsUrl={user.video.hls}
                        dashUrl={user.video.dash}
                        fileUrl={user.video.file}
                        autoPlay
                        onEnded={() => handleVideoEnd(i)}
                        width="100%"
                        height="100%"
                    />
                </div>
            ))}
        </div>
    );
}

function UserVideo({ cameraOn, onToggleCamera }) {
    return (
        <div className={styles.userVideo}>
            {cameraOn ? (
                <div className={styles.userCamera__cameraWrapper}>
                    <CameraVideo
                        className={styles.userCamera__camera}
                        onError={() => onToggleCamera(false)}
                    />
                </div>
            ) : null}
        </div>
    );
}

function Controls({ cameraOn, onEnd, onToggleCamera }) {
    return (
        <div className={styles.controls}>
            <ControlsButton icon="phone" isOn={true} onClick={onEnd} />
            <ControlsButton
                icon="videocam"
                isOn={cameraOn}
                onClick={() => onToggleCamera(!cameraOn)}
            />
        </div>
    );
}

function ControlsButton({ icon, isOn, onClick }) {
    return (
        <div
            className={classnames(
                styles.controls__button,
                styles[icon],
                isOn ? styles.on : null,
                `icon-${icon}`
            )}
            onClick={onClick}
        ></div>
    );
}
