import { useRef, useEffect } from "react";

export default function CameraVideo({ className, onError }) {
	const videoEl = useRef(null);

	useEffect(() => {
		let currentStream = null;

		if (navigator.mediaDevices?.getUserMedia) {
			navigator.mediaDevices
				.getUserMedia({ video: true })
				.then(function (stream) {
					currentStream = stream;
					videoEl.current.srcObject = stream;
				})
				.catch(function (err) {
					onError && onError();
				});
		}

		return () => {
			if (currentStream) {
				stopStream(currentStream);
			}
		};
	}, [onError]);

	return <video ref={videoEl} className={className} autoPlay />;
}

function stopStream(stream) {
	stream.getTracks().forEach(function (track) {
		track.stop();
	});
}
