import styles from "./SimpleVideoCallWebsite.module.css";
import VideoCall from "./VideoCall";
import config from "../config.json";

import Widget from "@faintlines/widget";
import "@faintlines/widget/dist/index.css";

import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
import classnames from "classnames";

export default function SimpleVideoCallWebsite() {
    return (
        <Router>
            <div className={classnames(styles.app, config.site.theme)}>
                <Route
                    path={["/join/:callId", "/join/:callId/:sessionId"]}
                    exact
                >
                    <VideoCallWrapper />
                </Route>
                {config.widget ? (
                    <Widget config={config.widget} storyConfig={config.story} />
                ) : null}
            </div>
        </Router>
    );
}

function VideoCallWrapper() {
    const { callId, sessionId } = useParams();
    const call = config.videoCalls[callId];
    if (!call) {
        return (
            <div className={styles.notFound}>
                {config.site.callNotFoundText}
            </div>
        );
    }
    return <VideoCall call={call} temporaryInteractionId={sessionId} />;
}
